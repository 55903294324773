.App {
  text-align: center;
  display:grid;
  place-items: center;
  align-items: center;
  justify-items: center;
  background: #bcaaa4;
}

.main{
  width:90vw;
  height:95vh;
}
.table-container {
  max-height: 400px; /* Adjust height based on the number of rows you want to display */
  overflow-y: auto;
  width: 300px; /* Adjust width as needed */
  margin-left: 20px; /* Adjust left margin as needed */
  text-align: center; /* Center text in the table */
}

.table-overlay {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 1000;
  width: 20vw;
  margin-bottom: 100px;
}

.table-container th,
.table-container td {
  border-bottom: 1px solid #ddd;
}

.table-container th {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #333;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-family: "Roman";   /*Can be any text*/
  src: local("Macedonia"),
    url("./Macedonia.ttf") format("truetype");
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
